<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 companies-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="applications-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("manage-companies", "companies", "companies") }}
        </div>
        <template v-slot:right>
          <router-link :to="{ name: 'r_add-company' }">
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper companies-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('manage-companies', 'companies', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
    </template>
    <ul
      v-if="listOfCompanies && listOfCompanies.length"
      class="clebex-item-section pill"
    >
      <li
        class="clebex-item-section-item cursor-pointer"
        v-for="company in listOfCompanies"
        :key="company.id"
      >
        <div v-if="selection" class="clebex-item-content-wrapper">
          <div class="checkbox tiny alt block">
            <input
              type="radio"
              :id="`user${company.id}`"
              name="user"
              :selected="company.id === selectedCompany"
              :value="company.id"
              @change="selectedCompany = company.id"
            />
            <label :for="`user${company.id}`" class="highlight">
              <icon icon="#cx-app1-checkmark" />
              {{ company.name }}</label
            >
          </div>
        </div>
        <div
          v-else
          @click="editCompany(company.id)"
          class="clebex-item-content-wrapper"
        >
          <span class="label text">
            {{ company.name }}
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon
                icon="#cx-app1-arrow-right-12x12"
                width="12"
                height="12"
              ></icon>
            </span>
          </span>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :disabled="processing"
              @click="selection = !selection"
            >
              {{ displayLabelName("manage-companies", "companies", "select") }}
            </button>
          </li>
          <li class="action">
            <button
              class="action-btn"
              :disabled="!selectedCompany || processing"
              @click="show = true"
            >
              {{ displayLabelName("manage-companies", "companies", "delete") }}
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :confirm-action="startDelete"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("manage-companies.companies.delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("manage-companies.companies.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import { mapActions, mapState } from "vuex";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";

export default {
  name: "Companies",
  data() {
    return {
      selectedCompany: 0,
      processing: false,
      show: false,
      searchQuery: "",
      selection: false
    };
  },
  created() {
    this.getCompanyTypes();
    this.getCountries();
    this.getCompanies();
    this.getDataRegions();
  },
  computed: {
    ...mapState("companies", ["companies"]),
    listOfCompanies() {
      const query = this.searchQuery;
      if (this.companies && this.companies.data && this.companies.data.length) {
        if (query && query.length > 1) {
          return this.companies.data.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.companies.data;
        }
      }
      return null;
    }
  },
  methods: {
    ...mapActions("companies", [
      "getCompanies",
      "setSelectedCompany",
      "deleteCompany",
      "getCompanyTypes"
    ]),
    ...mapActions("country", ["getCountries"]),
    ...mapActions("dataRegion", ["getDataRegions"]),
    selectCompany(id) {
      this.selectedCompany = id;
    },
    editCompany(id) {
      this.setSelectedCompany(
        this.companies.data.filter(item => item.id === id)
      );
      this.$router.push({ name: "r_edit-company" });
    },
    startDelete() {
      this.processing = true;
      this.deleteCompany(this.selectedCompany).finally(() => {
        this.processing = false;
        this.setSelectedCompany(null);
        this.$nextTick(() => {
          this.$router.push({ name: "r_manage-companies" });
        });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
